@media (max-width: 640px) {
.toolbar[data-v-88649792] {
    display: flex;
    justify-content: column;
}
.input[data-v-88649792] {
    width: 100%;
    margin-bottom: 8px;
}
[data-v-88649792] .el-date-editor.el-input {
    width: 100% !important;
    margin-top: 0px !important;
}
}
@media (min-width: 640px) {
.input[data-v-88649792] {
    margin-right: 8px !important;
}
}
